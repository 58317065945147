import axios from 'axios';
import CheckoutSteps from 'components/CheckoutSteps';
import {
  LeavePlaceOrderModal,
  OrderCanceledModal,
} from 'components/modals/OrderModals';
import { useContext, useEffect, useReducer, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Outlet, useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { Store } from 'Store';
import { checkCartHandler } from 'utils/utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return { ...state, loading: true };
    case 'SUCCESS':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default function PlaceOrderScreen() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: true,
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, reserved, id } = state;

  const [blockerEnabled, setBlockerEnabled] = useState(true);
  const [showOrderCanceled, setShowOrderCanceled] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const [timerStarted, setIsTimerStarted] = useState(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.startsWith('/bestellung/') &&
      blockerEnabled
  );

  useEffect(() => {
    // Checke Reservierung
    dispatch({ type: 'REQUEST' });

    const checkCart = async () => {
      return await checkCartHandler({
        dispatch,
        ctxDispatch,
        cartItems: cart.cartItems,
        reserved,
        id,
      });
    };
    const checkReserveProducts = async () => {
      const reserve = await axios.post(`/api/products/reserveproducts`, {
        params: {
          productids: cart.cartItems.map((p) => p._id),
          reservedUntil: new Date().getTime() + 15 * 60 * 1000,
          id,
        },
      });
      return reserve;
    };
    if (cart.cartItems.length === 0) {
      navigate(-1, { state: { prevPage: pathname } });
    } else {
      checkCart().then((success) => {
        if (success) {
          checkReserveProducts().then((reserve) => {
            if (reserve.status === 200) {
              ctxDispatch({
                type: 'RESERVED_PRODUCTS',
                payload: {
                  IDs: cart.cartItems.map((p) => p._id),
                  reservedUntil: new Date(reserve.data),
                },
              });
              setIsTimerStarted(true);
              dispatch({ type: 'SUCCESS' });
            } else {
              navigate(-1, { state: { prevPage: pathname } });
            }
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onComplete = () => {
    setIsCompleted(true);
    ctxDispatch({
      type: 'CANCEL_RESERVED_PRODUCTS',
    });
    setBlockerEnabled(false);
    setShowOrderCanceled(true);
  };

  const checkoutSteps = () => {
    switch (true) {
      case pathname.startsWith('/bestellung/lieferung'):
        return { step1: true, step2: true, step3: false, step4: false };
      case pathname.startsWith('/bestellung/bezahlmethode'):
        return { step1: true, step2: true, step3: true, step4: false };
      case pathname.startsWith('/bestellung/uebersicht'):
        return { step1: true, step2: true, step3: true, step4: true };
      default:
        return { step1: true, step2: false, step3: false, step4: false };
    }
  };

  return (
    <Container>
      <Helmet>
        <title>
          Bestellvorgang | BraunBärchen Online-Secondhand-Shop für Kinderbedarf
        </title>
      </Helmet>
      <LeavePlaceOrderModal blocker={blocker} products={cart.cartItems} />
      <OrderCanceledModal
        show={showOrderCanceled}
        onHide={() => {
          ctxDispatch({
            type: 'CANCEL_RESERVED_PRODUCTS',
          });
          navigate('/warenkorb', {
            state: { prevPage: pathname },
          });
        }}
      />
      {reserved.until && timerStarted && (
        <CheckoutSteps
          {...checkoutSteps()}
          reservedUntil={reserved.until ?? 0}
          onComplete={() => onComplete()}
        ></CheckoutSteps>
      )}

      <Outlet context={{ isCompleted, showOrderCanceled, loading }} />
    </Container>
  );
}
