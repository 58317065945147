import { useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import './css/pagination.css';

const Paginate = ({ totalProducts, productsPerPage, currentPage }) => {
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  const [activePage, setActivePage] = useState(currentPage);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (pageNumber, e) => {
    try {
      if (e && e.target) {
        e.target.blur();
      }
    } catch (error) {
      console.error(error);
    }
    setActivePage(pageNumber);

    const params = new URLSearchParams(location.search);
    params.set('page', pageNumber);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const renderPaginationItems = () => {
    let items = [];

    // Always show the first page, ellipsis if needed, and current page with neighbors
    if (activePage > 2) {
      items.push(
        <Pagination.Item key={1} onClick={(e) => handleClick(1, e)}>
          1
        </Pagination.Item>
      );
      if (activePage > 3) {
        items.push(
          <Pagination.Ellipsis
            key="start-ellipsis"
            className="page-indicator-ellipsis"
          />
        );
      }
    }

    // Display a range of 3 pages before and after the current page
    for (
      let i = Math.max(1, activePage - 1);
      i <= Math.min(totalPages, activePage + 1);
      i++
    ) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === activePage}
          onClick={(e) => handleClick(i, e)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Always show the last page, ellipsis if needed
    if (activePage < totalPages - 1) {
      if (activePage < totalPages - 2) {
        items.push(
          <Pagination.Ellipsis
            key="end-ellipsis"
            className="page-indicator-ellipsis"
          />
        );
      }
      items.push(
        <Pagination.Item
          key={totalPages}
          onClick={(e) => handleClick(totalPages, e)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <>
      <div className="pt-2 me-2 d-flex align-items-center">Seite:</div>
      <Pagination className="page-indicator pt-2">
        {/* Previous Button */}
        <Pagination.Prev
          onClick={(e) => handleClick(Math.max(1, activePage - 1), e)}
          disabled={activePage === 1}
        />

        {/* Render Pagination Items */}
        {renderPaginationItems()}

        {/* Next Button */}
        <Pagination.Next
          onClick={(e) => handleClick(Math.min(totalPages, activePage + 1), e)}
          disabled={activePage === totalPages}
        />
      </Pagination>
    </>
  );
};

export default Paginate;
