import { formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';
import { useState } from 'react';
import { Card, Table, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const UserCard = ({ user }) => {
  const [showAll, setShowAll] = useState(false);

  const handleToggle = () => {
    setShowAll(!showAll);
  };

  return (
    <Card className="m-2 p-1" style={{ fontSize: '0.5rem' }} border="success">
      <Card.Header>
        <Card.Title style={{ fontSize: '0.6rem' }}>
          Benutzer: {user.trackingNumber}
        </Card.Title>
        <Card.Subtitle className="text-muted" style={{ fontSize: '0.5rem' }}>
          Interaktionen: {user.count}
        </Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <div className="mt-1">
          <strong>{`Warenkorb: ${
            user.cartItems.length
          } Artikel - Gesamtwert: ${user.totalCartValue.toLocaleString(
            'de-DE',
            {
              style: 'currency',
              currency: 'EUR',
            }
          )}`}</strong>
          <ul className="list-unstyled">
            {user.cartItems.map((item, index) => (
              <Link to={`/product/${item}`} key={item}>
                <Badge key={index} pill bg="primary" className="me-2">
                  {item}
                </Badge>
              </Link>
            ))}
          </ul>
        </div>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Zeit</th>
              <th>Seite</th>
              <th>Aktion</th>
              <th>Filter</th>
            </tr>
          </thead>
          <tbody>
            {user.interactions
              .slice(user.interactions.length - 5, user.interactions.length)
              .reverse()
              .map((interaction, index) => (
                <tr key={index}>
                  <td>
                    {formatDistanceToNow(new Date(interaction.createdAt), {
                      addSuffix: true,
                      locale: de,
                    })}
                  </td>
                  <td>{interaction.page}</td>
                  <td>{interaction.action}</td>
                  <td>{JSON.stringify(interaction.filter)}</td>
                </tr>
              ))}
            {showAll &&
              user.interactions
                .slice(0, user.interactions.length - 5)
                .reverse()
                .map((interaction, index) => (
                  <tr key={index}>
                    <td>
                      {formatDistanceToNow(new Date(interaction.createdAt), {
                        addSuffix: true,
                        locale: de,
                      })}
                    </td>
                    <td>{interaction.page}</td>
                    <td>{interaction.action}</td>
                    <td>{JSON.stringify(interaction.filter)}</td>
                  </tr>
                ))}
          </tbody>
        </Table>
        {user.interactions.length > 3 && (
          <div
            onClick={handleToggle}
            style={{ fontSize: '0.5rem', color: 'blue', cursor: 'pointer' }}
          >
            {showAll ? 'Weniger anzeigen' : 'Mehr anzeigen'}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default UserCard;
