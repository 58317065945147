import { Store } from 'Store';
import axios from 'axios';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';

export const getError = (error) => {
  return error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
};

export const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [appState, setAppState] = useState({ isAdmin: false, loading: true });

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const authUser = async () => {
      if (userInfo && userInfo.token) {
        setAppState({
          ...appState,
          loading: true,
        });
        axios
          .get('/api/users/auth', {
            headers: {
              Authorization: `Baerer ${userInfo.token}`,
            },
          })
          .then(() => {
            setAppState({
              isAdmin: true,
              loading: false,
            });
          })
          .catch(() => {
            setAppState({
              isAdmin: false,
              loading: false,
            });
          });
      } else {
        setAppState({
          isAdmin: false,
          loading: false,
        });
      }
    };
    authUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <AuthContext.Provider value={appState}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const state = useContext(AuthContext);
  if (state === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return { ...state };
};

export default useAuth;

export function lazyLoadCSS(href) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  link.media = 'print';
  link.onload = function () {
    this.media = 'all';
  };
  document.head.appendChild(link);
}

export function getSizeText(p) {
  if (p.sizeHat && p.sizeHat.length > 0) {
    if (p.sizeHat.length >= 2) {
      return `Hut-${Math.min(...p.sizeHat)}-${Math.max(...p.sizeHat)}`;
    } else {
      return `Hut-${p.sizeHat[0]}`;
    }
  } else {
    return `${p.size}`;
  }
}

export function useCurrentHeight() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { lastHeight } = state;

  const mainContainer = document.getElementById('mainContainer');

  const setHeight = useCallback(() => {
    ctxDispatch({
      type: 'CONTAINER_HEIGHT',
      payload: mainContainer.offsetHeight,
    });
  }, [ctxDispatch, mainContainer.offsetHeight]);

  return [lastHeight, setHeight];
}

export function getCDNImage(path) {
  try {
    const cdnPath = path.replace(
      'braunbaerchenproductimages.s3.eu-central-1.amazonaws.com',
      'cdn.braunbaerchen.de'
    );
    return cdnPath;
  } catch (error) {
    console.log(path);
    return '';
  }
}

export async function checkCartHandler(props) {
  const { dispatch, ctxDispatch, cartItems, reserved, id } = props;

  dispatch({ type: 'REQUEST' });

  let success = true;

  const { data } = await axios.get(`/api/products/checkcart`, {
    params: {
      productids: cartItems.map((p) => p._id),
    },
  });
  const totalPrice = round2(
    data.products.reduce(
      (a, c) =>
        c.sale
          ? a + Math.round(c.price * (1 - c.discount / 100) * 1e2) / 1e2
          : a + c.price,
      0
    )
  );
  ctxDispatch({ type: 'UPDATE_TOTALPRICE', payload: totalPrice });

  if (data.soldProducts.length > 0) {
    success = false;
    data.soldProducts.forEach((item) => {
      let removedProduct = document.getElementById(item._id);
      removedProduct.style.opacity = '0';
    });
  }

  if (data.notFound.length > 0) {
    success = false;
    data.notFound.forEach((id) => {
      let removedProduct = document.getElementById(id);
      removedProduct.style.opacity = '0';
    });
  }

  if (data.products.length > 0) {
    data.products.forEach((item) => {
      let cartProduct = cartItems.find((p) => p._id === item._id);
      if (
        item.price !== cartProduct.price ||
        item.discount !== cartProduct.discount ||
        item.sale !== cartProduct.sale
      ) {
        success = false;
      } else if (
        new Date(item.reservedUntil) > new Date() &&
        !reserved.IDs.some((p) => p === item._id) &&
        item.reservedBy !== id
      ) {
        success = false;
        let removedProduct = document.getElementById(item._id);
        removedProduct.style.opacity = '0';
      } else if (item.sale !== cartProduct.sale) {
        success = false;
      }
    });
  }

  if (!success) {
    toast.info(
      <div className="d-flex">
        <div>{`Die Artikel in deinem Warenkorb wurden aktualisiert.`}</div>
      </div>,
      { draggable: false }
    );
    setTimeout(() => {
      ctxDispatch({
        type: 'CART_UPDATE_ITEMS',
        payload: { data },
      });
    }, 2000);
    dispatch({ type: 'SUCCESS' });
    return false;
  } else {
    dispatch({ type: 'SUCCESS' });
    return true;
  }
}
