import { Store } from 'Store';
import axios from 'axios';
import { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { deliveryCosts, deliveryEnum } from 'utils/types';

export function LeavePlaceOrderModal(props) {
  const { blocker, products } = props;

  const { dispatch: ctxDispatch } = useContext(Store);

  const cancelOrderHandler = async () => {
    // Cancel reservation
    await axios.post(`/api/products/cancelreservation`, {
      params: {
        productids: products.map((p) => p._id),
      },
    });
    ctxDispatch({
      type: 'CANCEL_RESERVED_PRODUCTS',
    });
    blocker.proceed();
  };

  return (
    <Modal
      show={blocker.state === 'blocked'}
      onHide={() => blocker.reset()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>Achtung</Modal.Header>
      <Modal.Body>
        <p>
          Willst du den Bestellvorgang abbrechen?
          <br />
          Die Artikel sind dann <strong>nicht mehr</strong> reserviert.
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Row className="w-100">
          <Col
            xs={{ span: 5, offset: 1 }}
            sm={{ span: 4, offset: 2 }}
            md={{ span: 4, offset: 2 }}
            className="d-flex justify-content-center"
          >
            <div
              className="no-mw btn_action w-100"
              onClick={cancelOrderHandler}
            >
              Ja
            </div>
          </Col>
          <Col
            xs={5}
            sm={{ span: 4, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            className="d-flex justify-content-center"
          >
            <div
              className="no-mw btn_action w-100"
              onClick={() => blocker.reset()}
            >
              Nein
            </div>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export function OrderCanceledModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{`Zeit abgelaufen :(`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Deine Zeit ist leider abgelaufen. Deine Bestellung wurde abgebrochen.
          <br />
          Du wirst zum Warenkorb weitergeleitet.
        </p>
      </Modal.Body>
    </Modal>
  );
}

export function PlaceOrderModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p>Ist deine Email Adresse korrekt?</p>
        <h6 className="text-center">{props.eMail}</h6>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Row className="w-100">
          <Col
            xs={{ span: 5, offset: 1 }}
            sm={{ span: 4, offset: 2 }}
            md={{ span: 4, offset: 2 }}
            className="d-flex justify-content-center"
          >
            <div
              className="no-mw btn_action w-100"
              onClick={props.onCorrectAddress}
            >
              Ja
            </div>
          </Col>
          <Col
            xs={5}
            sm={{ span: 4, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            className="d-flex justify-content-center"
          >
            <div className="no-mw btn_action w-100" onClick={props.onHide}>
              Nein
            </div>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export function ReallyDhlModal(props) {
  const { saveDeliveryData, ...rest } = props;
  return (
    <Modal
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Bist du dir sicher?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Nach Wipperfürth und Hückeswagen liefern wir auch persönlich für nur{' '}
          <strong>
            {deliveryCosts[deliveryEnum.LiefernInnerhalb].toLocaleString(
              'de-DE',
              {
                style: 'currency',
                currency: 'EUR',
              }
            )}
          </strong>
          <br />
          <br />
          Möchtest du deine Bestellung trotzdem lieber mit DHL für{' '}
          <strong>
            {deliveryCosts[deliveryEnum.LiefernDHL].toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
            })}
          </strong>{' '}
          geliefert bekommen?
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Row className="w-100">
          <Col
            xs={{ span: 5, offset: 1 }}
            sm={{ span: 4, offset: 2 }}
            md={{ span: 4, offset: 2 }}
            className="d-flex justify-content-center"
          >
            <div
              className="no-mw btn_action w-100"
              onClick={() => saveDeliveryData()}
            >
              Ja
            </div>
          </Col>
          <Col
            xs={5}
            sm={{ span: 4, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            className="d-flex justify-content-center"
          >
            <div className="no-mw btn_action w-100" onClick={props.onHide}>
              Nein
            </div>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
