import '../components/css/checkoutsteps.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';

export default function CheckoutSteps(props) {
  const renderer = ({ formatted: { minutes, seconds } }) => {
    // Render a countdown
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  };

  var countdownApi = undefined;

  const setRef = (countdown) => {
    if (countdown) {
      countdownApi = countdown.getApi();
    }
  };

  useEffect(() => {
    if (props.pause) {
      countdownApi && countdownApi.pause();
    } else {
      countdownApi && countdownApi.start();
    }
  }, [props.pause, countdownApi]);

  const [reservedUntil, setReservedUntil] = useState();

  useEffect(() => {
    setReservedUntil(props.reservedUntil);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row className="checkout-steps">
        <Col
          className={`d-flex justify-content-center ${
            props.step1 ? 'active' : ''
          }`}
        >
          <div className="text-center h-100 d-flex align-items-center">
            Deine Daten
          </div>
        </Col>
        <Col
          className={`d-flex justify-content-center ${
            props.step2 ? 'active' : ''
          }`}
        >
          <div className="text-center h-100 d-flex align-items-center">
            Lieferung
          </div>
        </Col>
        <Col
          className={`d-flex justify-content-center ${
            props.step3 ? 'active' : ''
          }`}
        >
          <div className="text-center h-100 d-flex align-items-center">
            Bezahlart
          </div>
        </Col>
        <Col
          className={`d-flex justify-content-center ${
            props.step4 ? 'active' : ''
          }`}
        >
          <div className="text-center h-100 d-flex align-items-center">
            Übersicht
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className={`checkout-col-circle ${props.step1 ? 'active' : ''}`}>
          <div
            className={`checkout-circle ${props.step1 ? 'active' : ''}`}
          ></div>
        </Col>
        <Col className={`checkout-col-circle ${props.step2 ? 'active' : ''}`}>
          <div
            className={`checkout-circle ${props.step2 ? 'active' : ''}`}
          ></div>
        </Col>
        <Col className={`checkout-col-circle ${props.step3 ? 'active' : ''}`}>
          <div
            className={`checkout-circle ${props.step3 ? 'active' : ''}`}
          ></div>
        </Col>
        <Col className={`checkout-col-circle ${props.step4 ? 'active' : ''}`}>
          <div
            className={`checkout-circle ${props.step4 ? 'active' : ''}`}
          ></div>
        </Col>
      </Row>

      <Row className="mb-3 w-100">
        <Col xs={9} className="d-flex align-items-center justify-content-end">
          <div style={{ fontSize: '1.25rem', fontWeight: '500' }}>
            Zeit verbleibend:
          </div>
        </Col>
        <Col
          xs={3}
          className="d-flex align-items-center justify-content-center"
        >
          <div style={{ fontSize: '1.25rem', fontWeight: '500' }}>
            <Countdown
              date={props.reservedUntil || reservedUntil}
              renderer={renderer}
              zeroPadTime={2}
              onComplete={props.onComplete}
              ref={setRef}
            />
          </div>
        </Col>
      </Row>

      {props.step1 && !props.step2 && (
        <Row>
          <Col>
            <div className="mb-3" style={{ fontWeight: '500' }}>
              Die Artikel sind jetzt 15 Minuten für dich reserviert!
            </div>
          </Col>
        </Row>
      )}

      <Row className="mb-4">
        <Col style={{ borderBottom: '2px solid #a0a0a0' }}></Col>
      </Row>
    </>
  );
}
