import { useEffect } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Product from '../components/Product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PullToRefresh from 'react-simple-pull-to-refresh';
import PullingContent from '../components/PullingContent';
import { Card } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import Paginate from 'components/Paginate';

const ShopProducts = () => {
  const {
    shopType,
    products,
    productsCount,
    handleRefresh,
    setFilterIsOpen,
    filterIsOpen,
    page,
  } = useOutletContext();

  useEffect(() => {
    const screenResize = () => {
      for (const element of document.getElementsByClassName('price-tag')) {
        var size = parseInt(
          getComputedStyle(element).getPropertyValue('font-size')
        );
        const parent_width = parseInt(
          getComputedStyle(element.parentElement).getPropertyValue('width')
        );
        while (element.offsetWidth > parent_width) {
          element.style.fontSize = size + 'px';
          size -= 1;
        }
        while (element.offsetWidth <= parent_width && size < 19) {
          element.style.fontSize = size + 'px';
          size += 1;
        }
      }
    };
    window.addEventListener('resize', screenResize);

    screenResize();

    return () => window.removeEventListener('resize', screenResize);
  });

  return (
    <PullToRefresh
      onRefresh={handleRefresh}
      isPullable={!filterIsOpen}
      className="pullrefresh"
      pullingContent={<PullingContent />}
    >
      <div className="d-flex flex-wrap justify-content-center gx-0">
        <Row
          className="position-fixed w-100 justify-content-center"
          style={{
            zIndex: 4,
            paddingRight: '0.75rem',
            paddingLeft: '0.75rem',
          }}
        >
          <div
            className="btn_action"
            onClick={() => setFilterIsOpen(!filterIsOpen)}
          >
            <FontAwesomeIcon icon="sliders" />
            {` Filter`}
          </div>
        </Row>

        {productsCount > 0 ? (
          <>
            <Row className="w-100 pt-5">
              <Col className="d-flex justify-content-end">
                <Paginate
                  totalProducts={productsCount}
                  productsPerPage={60}
                  currentPage={page}
                />
              </Col>
            </Row>
            <Row
              className={`w-100 mb-3 gx-0${
                filterIsOpen ? ' block-scroll' : ''
              }`}
              id="product-container"
            >
              {products.map((product) => (
                <Col
                  key={product._id}
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                  className="d-flex align-items-stretch"
                >
                  <Card
                    className="m-1 w-100 productcard"
                    border="light"
                    /* style={{ borderColor: 'lightgray' }} */
                  >
                    <Link
                      to={`/shop/${shopType.toLowerCase()}/product/${
                        product._id
                      }`}
                      className="h-100"
                      style={{ textDecoration: 'none' }}
                    >
                      <Product
                        product={product}
                        onSelect={() => {
                          ReactGA.event(
                            `Artikel öffnen - ${product.category} - ${product.size} - ${product.brand}`,
                            { ...product }
                          );
                          //navigate(`/shop/${shopType}/${product._id}`);
                          //trackUserAction(product._id);
                        }}
                      ></Product>
                    </Link>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row className="w-100 mb-3">
              <Col className="d-flex justify-content-end">
                <Paginate
                  totalProducts={productsCount}
                  productsPerPage={60}
                  currentPage={page}
                />
              </Col>
            </Row>
          </>
        ) : (
          <div className="d-flex justify-content-center pt-4 my-5 h3">
            Leider sind gerade alle Artikel mit den gewählten Kriterien
            vergriffen. Schaue später noch einmal vorbei
          </div>
        )}
      </div>
    </PullToRefresh>
  );
};

export default ShopProducts;
