import axios from 'axios';
import { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Store } from 'Store';

export default function BecomeSellerScreen() {
  const { state } = useContext(Store);
  const { id, cart } = state;

  useEffect(() => {
    const userAction = async () => {
      await axios.get('/api/users/opensite', {
        headers: {
          userid: id,
          cart: cart.cartItems.map((c) => c._id) || [],
          page: 'Verkäufer werden',
        },
      });
    };
    userAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="mb-3 text-center info">
      <Helmet>
        <title>
          Werde Verkäufer/in | BraunBärchen Online-Secondhand-Shop für
          Kinderbedarf
        </title>
      </Helmet>
      <h2>Werde Verkäufer/in</h2>
      <Row className="d-flex justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <p align="justify" style={{ hyphens: 'auto' }}>
            Willkommen bei "BraunBärchen" - Deinem exklusiven
            Secondhand-Online-Shop! Hier geben wir deinen Kleidungsstücken eine
            zweite Chance und bieten dir die Möglichkeit, stilvoll zu verkaufen.
            <br />
            <br />
            Unser Konzept beruht auf Kommissionsbasis: Wir übernehmen deine
            hochwertige, saisonale Kleidung und setzen den Verkaufspreis für
            dich fest. Mit einem fairen Kommissionsanteil von 40% ermöglichen
            wir eine win-win Situation.
            <br />
            <br />
            Damit wir die beste Auswahl präsentieren können, akzeptieren wir nur
            einwandfreie Kleidung - keine Löcher, keine Flecken und die Schilder
            dürfen nicht fehlen. Wir legen Wert auf Frische, daher sollte die
            Kleidung gewaschen und nicht älter als 5 Jahre sein.
            <br />
            <br />
            Deine Schätze verbleiben 6 Monate in unserem Shop, danach kannst du
            sie gerne wieder abholen. Unser Team ist stets bemüht, die Ware
            bestmöglich zu präsentieren und so schnell wie möglich zu verkaufen.
            <br />
            <br />
            Möchtest du Teil von BraunBärchen werden? Schick uns eine Nachricht,
            um mehr über die unkomplizierte Abwicklung zu erfahren und gemeinsam
            nachhaltige Mode zu fördern.
            <br />
            <br />
            <br />
          </p>
        </Col>
      </Row>
    </Container>
  );
}
