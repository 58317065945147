import React from 'react';

const PullingContent = () => {
  return (
    <div className="text-center h6 mt-2">
      <p>&#8615;&nbsp;&nbsp;ziehen zum Aktualisieren&nbsp;&nbsp;&#8615;</p>
    </div>
  );
};

export default PullingContent;
