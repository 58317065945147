import '../components/css/product.css';
import Card from 'react-bootstrap/Card';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import { Col, Stack } from 'react-bootstrap';
import { getCDNImage } from 'utils/utils';

function Product(props) {
  const { loadLazy } = props;
  const { state } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const [product] = useState(props.product);
  const [scrollTop, setScrollTop] = useState();

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  const colorClass = (season) => {
    switch (season) {
      case 'Sommer':
        return 'red';
      case '-':
        return 'orange';
      case 'Übergang':
        return 'green';
      case 'Winter':
        return 'blue';
      default:
        break;
    }
  };

  return (
    <Card
      className={`product p-2 border-0 text-center h-100`}
      border="light"
      onClick={props.onSelect}
      style={{
        background: 'transparent',
        cursor: 'pointer',
        overflow: 'hidden',
      }}
    >
      {product.season && product.season !== '' && (
        <div className={`ribbon bg-${colorClass(product.season)}`}>
          <strong>{`${product.season}`}</strong>
        </div>
      )}
      {product.sale && (
        <div className="position-absolute p-1" style={{ zIndex: '3' }}>
          <span className="sale-tag p-1">{`-${product.discount}%`}</span>
        </div>
      )}
      {product.images.length > 0 ? (
        <Carousel variant="dark" className="" controls={false}>
          <CarouselItem>
            {/* <Link to={`/product/${product._id}`}> */}
            <img
              src={`${getCDNImage(product.image).replace(
                '.png',
                '_lowres.png'
              )}`}
              alt={product.name}
              draggable={false}
              className={`product__image ${
                cartItems.find((p) => p._id === product._id) ||
                (product.reservedUntil &&
                  new Date(product.reservedUntil) > new Date())
                  ? 'product__image_incart'
                  : ''
              }`}
              loading={loadLazy ? 'lazy' : 'eager'}
            />
            {/*  </Link>*/}
            {product.reservedUntil &&
              new Date(product.reservedUntil) > new Date() && (
                <span className="reservedSpan">Bereits reserviert</span>
              )}
          </CarouselItem>
          {product.images.map((i) => {
            return (
              <CarouselItem key={i}>
                {/*   <Link to={`/product/${product._id}`}> */}
                <img
                  src={`${getCDNImage(i).replace('.png', '_lowres.png')}`}
                  className={`product__image ${
                    cartItems.find((p) => p._id === product._id) ||
                    (product.reservedUntil &&
                      new Date(product.reservedUntil) > new Date())
                      ? 'product__image_incart'
                      : ''
                  }`}
                  alt={product.name}
                  draggable={false}
                  loading={loadLazy ? 'lazy' : 'eager'}
                />
                {/*     </Link> */}
                {product.reservedUntil &&
                  new Date(product.reservedUntil) > new Date() && (
                    <span className="reservedSpan">Bereits reserviert</span>
                  )}
              </CarouselItem>
            );
          })}
        </Carousel>
      ) : (
        <div style={{ position: 'relative' }}>
          {/*    <Link to={`/product/${product._id}`}> */}
          <div>
            <img
              src={`${getCDNImage(product.image).replace(
                '.png',
                '_lowres.png'
              )}`}
              alt={product.name}
              draggable={false}
              className={`card-img-top product__image ${
                cartItems.find((p) => p._id === product._id) ||
                (product.reservedUntil &&
                  new Date(product.reservedUntil) > new Date())
                  ? 'product__image_incart'
                  : ''
              }`}
              loading={loadLazy ? 'lazy' : 'eager'}
            />
          </div>
          {/*   </Link> */}
          {product.reservedUntil &&
            new Date(product.reservedUntil) > new Date() && (
              <span className="reservedSpan">Bereits reserviert</span>
            )}
        </div>
      )}
      <Card.Body className="p-1 d-flex flex-column">
        <Card.Title>{product.category}</Card.Title>
        <Row className="">
          <Col className="d-flex flex-column justify-content-between">
            <Row
              className={`d-flex justify-content-center productSize ${
                product.sale ? 'mb-0' : 'mb-4'
              }`}
            >
              {product.brand}
            </Row>
            <div>
              {product.sale && (
                <Row className="">
                  <Col className="p-0 d-flex justify-content-end">
                    <span
                      className="me-1"
                      style={{
                        color: 'black',
                        textDecoration: 'line-through',
                        textDecorationThickness: '1px',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '0.9rem',
                          color: '#737373',
                          fontWeight: '300',
                        }}
                      >
                        {product.price.toLocaleString('de-DE', {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </div>
                    </span>
                  </Col>
                </Row>
              )}
              <Row className="d-flex justify-content-center">
                <Col
                  xs={6}
                  className="text-start pe-0 productSize d-flex align-items-end"
                >
                  {product.sizeHat && product.sizeHat.length > 0 ? (
                    <Stack gab={0}>
                      <div>Hutgr.</div>
                      {product.sizeHat.length >= 2 ? (
                        <div>
                          {Math.min(...product.sizeHat)} -{' '}
                          {Math.max(...product.sizeHat)}
                        </div>
                      ) : (
                        <div>{product.sizeHat[0]}</div>
                      )}
                    </Stack>
                  ) : (
                    <>{product.size}</>
                  )}
                </Col>
                <Col
                  xs={6}
                  className="text-end p-0 d-flex justify-content-end align-items-end productPrice"
                >
                  {product.sale ? (
                    <div className="price-tag price-tag-sale">
                      {(
                        Math.round(
                          product.price * (1 - product.discount / 100) * 1e2
                        ) / 1e2
                      ).toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </div>
                  ) : (
                    <div className="price-tag">
                      {product.price.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
export default Product;
